import React, { useEffect, useState } from "react";
import { customFetch } from "../utils/customFetch.js";
import { getApiRoot } from "../utils/getApiRoot.js";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import {
  Search,
  Calendar,
  User,
  Bus,
  Building2,
  Users,
  Phone,
  Mail,
  FileText,
  CreditCard,
  X
} from "lucide-react";
import { Popover, PopoverTrigger, PopoverContent } from "./ui/popover";
import { Calendar as ShadcnCalendar } from "./ui/calendar";
import { format } from "date-fns";

const defaultTripDateRange = {
  departureDateFrom: "",
  departureDateTo: "",
  returnDateFrom: "",
  returnDateTo: "",
};

const FilterComponent = ({ currentPage, setCurrentPage, setTrips }) => {
  const [filter1Type, setFilter1Type] = useState("lastName");
  const [filter1Text, setFilter1Text] = useState("");
  const [filter2, setFilter2] = useState("tripStatus");
  const [filter3, setFilter3] = useState("paymentStatus");
  const [tripDateRange, setTripDateRange] = useState(defaultTripDateRange);
  const [openDepartureFrom, setOpenDepartureFrom] = useState(false);
  const [openDepartureTo, setOpenDepartureTo] = useState(false);
  const [openReturnFrom, setOpenReturnFrom] = useState(false);
  const [openReturnTo, setOpenReturnTo] = useState(false);

  const clearAllFilters = () => {
    setCurrentPage(1);
    setFilter1Type("lastName");
    setFilter1Text("");
    setFilter2("tripStatus");
    setFilter3("paymentStatus");
    setTripDateRange(defaultTripDateRange);
    setTrips([]);
  };

  useEffect(() => {
    const searchTrips = async () => {
      const queryParams = {};

      if (filter1Text) queryParams[filter1Type] = filter1Text;
      if (filter2 !== "tripStatus") queryParams.tripStatus = filter2;
      if (filter3 !== "paymentStatus") queryParams.paymentStatus = filter3;

      if (tripDateRange.departureDateFrom && tripDateRange.departureDateTo) {
        queryParams.departureDateMillisFrom = new Date(
          tripDateRange.departureDateFrom
        ).getTime();
        queryParams.departureDateMillisTo =
          new Date(tripDateRange.departureDateTo).getTime() +
          24 * 60 * 60 * 1000 -
          1;
      }
      if (tripDateRange.returnDateFrom && tripDateRange.returnDateTo) {
        queryParams.returnDateMillisFrom = new Date(
          tripDateRange.returnDateFrom
        ).getTime();
        queryParams.returnDateMillisTo =
          new Date(tripDateRange.returnDateTo).getTime() +
          24 * 60 * 60 * 1000 -
          1;
      }

      const queryString = new URLSearchParams(queryParams).toString();

      try {
        if (queryString) {
          const options = {
            credentials: "include",
            headers: {
              "content-type": "application/json",
            },
            method: "GET",
          };

          const response = await customFetch(
            getApiRoot() + `/api/trips/search?${queryString}`,
            options
          );

          if (response.ok) {
            let result = await response.json();
            result = result
              .map((item) => ({
                ...item,
                quoteDate: new Date(item.quoteDate),
                departureDate: new Date(item.departureDate),
                returnDate: new Date(item.returnDate),
              }))
              .sort((a, b) => b.quoteDate - a.quoteDate);
            setCurrentPage(1);
            setTrips(result);
          } else {
            console.error("Error searching for trips:", response.statusText);
            setTrips([]);
          }
        }
      } catch (error) {
        console.error("Failed to fetch trips:", error);
        setTrips([]);
      }
    };

    searchTrips();
  }, [
    filter1Type,
    filter1Text,
    filter2,
    filter3,
    tripDateRange,
    setCurrentPage,
    setTrips,
  ]);

  return (
    <div className="space-y-6 p-6 bg-background border rounded-lg shadow-lg divide-y divide-border dark:divide-border/30">
      {/* Main Filters Row */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 pb-6">
        {/* Search Group */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-foreground/90 flex items-center gap-2">
            <Search size={16} className="text-muted-foreground" />
            Search Trips
          </label>
          <div className="flex flex-col sm:flex-row gap-2">
            <Select value={filter1Type} onValueChange={setFilter1Type}>
              <SelectTrigger className="w-full sm:w-48 border border-black dark:border-white border-input bg-background hover:bg-accent/50 transition-colors">
                <SelectValue placeholder="Search by..." />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="lastName">
                  <span className="flex items-center gap-2">
                    <User size={16} /> Customer Last Name
                  </span>
                </SelectItem>
                <SelectItem value="driverName">
                  <span className="flex items-center gap-2">
                    <User size={16} /> Driver Name
                  </span>
                </SelectItem>
                <SelectItem value="busName">
                  <span className="flex items-center gap-2">
                    <Bus size={16} /> Bus Name
                  </span>
                </SelectItem>
                <SelectItem value="tripNumber">
                  <span className="flex items-center gap-2">
                    <FileText size={16} /> Quote Number
                  </span>
                </SelectItem>
                <SelectItem value="companyName">
                  <span className="flex items-center gap-2">
                    <Building2 size={16} /> Company Name
                  </span>
                </SelectItem>
                <SelectItem value="groupLeader">
                  <span className="flex items-center gap-2">
                    <Users size={16} /> Group Leader
                  </span>
                </SelectItem>
                <SelectItem value="groupName">
                  <span className="flex items-center gap-2">
                    <Users size={16} /> Group Name
                  </span>
                </SelectItem>
                <SelectItem value="phone">
                  <span className="flex items-center gap-2">
                    <Phone size={16} /> Reservation Phone
                  </span>
                </SelectItem>
                <SelectItem value="email">
                  <span className="flex items-center gap-2">
                    <Mail size={16} /> Reservation Email
                  </span>
                </SelectItem>
              </SelectContent>
            </Select>
            <div className="relative flex-1">
              <Search
                size={16}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground"
              />
              <Input
                type="text"
                placeholder="Enter search term..."
                value={filter1Text}
                onChange={(e) => setFilter1Text(e.target.value)}
                className="pl-9 pr-4 w-full bg-background hover:border-ring transition-colors border-black dark:border-white"
              />
            </div>
          </div>
        </div>

        {/* Status Filters */}
        <div className="space-y-2">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 bg-muted/30 p-4 rounded-md border border-border/50">
            <div className="space-y-2">
              <label className="text-sm font-medium text-foreground/90 flex items-center gap-2">
                <FileText size={16} className="text-muted-foreground" />
                Quote Status
              </label>
              <Select value={filter2} onValueChange={setFilter2}>
                <SelectTrigger className="w-full bg-background hover:bg-accent/50 transition-colors border-black dark:border-white">
                  <SelectValue placeholder="Quote Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="tripStatus">All Statuses</SelectItem>
                  <SelectItem value="Draft">Draft</SelectItem>
                  <SelectItem value="Pending">Pending</SelectItem>
                  <SelectItem value="Accepted">Accepted</SelectItem>
                  <SelectItem value="Cancelled">Cancelled</SelectItem>
                  <SelectItem value="Rejected">Rejected</SelectItem>
                  <SelectItem value="Complete">Complete</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-foreground/90 flex items-center gap-2">
                <CreditCard size={16} className="text-muted-foreground" />
                Payment Status
              </label>
              <Select value={filter3} onValueChange={setFilter3}>
                <SelectTrigger className="w-full bg-background hover:bg-accent/50 transition-colors border-black dark:border-white">
                  <SelectValue placeholder="Payment Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="paymentStatus">All Statuses</SelectItem>
                  <SelectItem value="Unpaid">Unpaid</SelectItem>
                  <SelectItem value="Deposit">Deposit</SelectItem>
                  <SelectItem value="Paid">Paid</SelectItem>
                  <SelectItem value="Refunded">Refunded</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>

        {/* Clear Button */}
        <div className="flex items-end">
          <Button
            variant="outline"
            onClick={clearAllFilters}
            className="w-full hover:bg-destructive/10 hover:text-destructive hover:border-destructive/30 transition-colors border-black dark:border-white group"
          >
            <X size={16} className="mr-2 group-hover:scale-110 transition-transform" />
            Clear All Filters
          </Button>
        </div>
      </div>

      {/* Date Ranges Row */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pt-6">
        {/* Departure Date Range */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-foreground/90 flex items-center gap-2">
            <Calendar size={16} className="text-muted-foreground" />
            Departure Date Range
          </label>
          <div className="flex flex-col sm:flex-row items-center gap-2">
            <Popover open={openDepartureFrom} onOpenChange={setOpenDepartureFrom}>
              <PopoverTrigger asChild>
                <Button variant="outline" className="w-full justify-start text-left font-normal border-black dark:border-white">
                  <Calendar size={16} className="mr-2" />
                  {tripDateRange.departureDateFrom
                    ? format(tripDateRange.departureDateFrom, "MM/dd/yyyy")
                    : "Start date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <ShadcnCalendar
                  mode="single"
                  selected={tripDateRange.departureDateFrom}
                  onSelect={(date) =>
                    setTripDateRange((prev) => ({
                      ...prev,
                      departureDateFrom: date,
                    }))
                  }
                  initialFocus
                />
              </PopoverContent>
            </Popover>
            <span className="text-sm text-muted-foreground/70 px-2">to</span>
            <Popover open={openDepartureTo} onOpenChange={setOpenDepartureTo}>
              <PopoverTrigger asChild>
                <Button variant="outline" className="w-full justify-start text-left font-normal border-black dark:border-white">
                  <Calendar size={16} className="mr-2" />
                  {tripDateRange.departureDateTo
                    ? format(tripDateRange.departureDateTo, "MM/dd/yyyy")
                    : "End date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <ShadcnCalendar
                  mode="single"
                  selected={tripDateRange.departureDateTo}
                  onSelect={(date) =>
                    setTripDateRange((prev) => ({
                      ...prev,
                      departureDateTo: date,
                    }))
                  }
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>

        {/* Return Date Range */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-foreground/90 flex items-center gap-2">
            <Calendar size={16} className="text-muted-foreground" />
            Return Date Range
          </label>
          <div className="flex flex-col sm:flex-row items-center gap-2">
            <Popover open={openReturnFrom} onOpenChange={setOpenReturnFrom}>
              <PopoverTrigger asChild>
                <Button variant="outline" className="w-full justify-start text-left font-normal border-black dark:border-white">
                  <Calendar size={16} className="mr-2" />
                  {tripDateRange.returnDateFrom
                    ? format(tripDateRange.returnDateFrom, "MM/dd/yyyy")
                    : "Start date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <ShadcnCalendar
                  mode="single"
                  selected={tripDateRange.returnDateFrom}
                  onSelect={(date) =>
                    setTripDateRange((prev) => ({
                      ...prev,
                      returnDateFrom: date,
                    }))
                  }
                  initialFocus
                />
              </PopoverContent>
            </Popover>
            <span className="text-sm text-muted-foreground/70 px-2">to</span>
            <Popover open={openReturnTo} onOpenChange={setOpenReturnTo}>
              <PopoverTrigger asChild>
                <Button variant="outline" className="w-full justify-start text-left font-normal border-black dark:border-white">
                  <Calendar size={16} className="mr-2" />
                  {tripDateRange.returnDateTo
                    ? format(tripDateRange.returnDateTo, "MM/dd/yyyy")
                    : "End date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <ShadcnCalendar
                  mode="single"
                  selected={tripDateRange.returnDateTo}
                  onSelect={(date) =>
                    setTripDateRange((prev) => ({
                      ...prev,
                      returnDateTo: date,
                    }))
                  }
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
