import React from 'react';
import PropTypes from 'prop-types';
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Button } from '../ui/button';
import viewedEye from '../images/eyeRegular.svg';
import notViewedYetEye from '../images/eyeSlashRegular.svg';
import editIcon from '../images/penToSquareRegular.svg';
import TableExportButtons from '../TableExportButtons';

const TripsTable = ({ trips, setTripModalData, setShowTripModal, currentPage, setCurrentPage }) => {
  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short'
      });
    } catch (error) {
      return dateString;
    }
  };

  const handleEditClick = (rowData) => {
    // Create a copy of the row data
    const modalData = { ...rowData };
    
    // Debug log
    console.log('Original dates:', {
      departure: modalData.departureDate,
      return: modalData.returnDate
    });
    
    // Convert date strings to Date objects
    if (modalData.departureDate) {
      try {
        // If it's already a Date object, keep it as is
        if (!(modalData.departureDate instanceof Date)) {
          // Try to parse the ISO string
          const parsedDate = new Date(modalData.departureDate);
          if (!isNaN(parsedDate.getTime())) {
            modalData.departureDate = parsedDate;
          } else {
            console.error('Invalid departure date format:', modalData.departureDate);
            modalData.departureDate = null;
          }
        }
      } catch (error) {
        console.error('Error parsing departure date:', error);
        modalData.departureDate = null;
      }
    }
    
    if (modalData.returnDate) {
      try {
        // If it's already a Date object, keep it as is
        if (!(modalData.returnDate instanceof Date)) {
          // Try to parse the ISO string
          const parsedDate = new Date(modalData.returnDate);
          if (!isNaN(parsedDate.getTime())) {
            modalData.returnDate = parsedDate;
          } else {
            console.error('Invalid return date format:', modalData.returnDate);
            modalData.returnDate = null;
          }
        }
      } catch (error) {
        console.error('Error parsing return date:', error);
        modalData.returnDate = null;
      }
    }

    // Debug log
    console.log('Converted dates:', {
      departure: modalData.departureDate,
      return: modalData.returnDate
    });

    setTripModalData(modalData);
    setShowTripModal(true);
  };

  const columns = [
    // Core Trip Information
    { accessorKey: 'tripId', header: 'QUOTE #' },
    { 
      accessorKey: 'client',
      header: 'CLIENT',
      cell: ({ row }) => `${row.original.firstName || ''} ${row.original.lastName || ''}`
    },
    { accessorKey: 'companyName', header: 'COMPANY' },
    
    // Trip Status Group
    { accessorKey: 'tripStatus', header: 'STATUS' },
    { accessorKey: 'paymentStatus', header: 'PAYMENT' },
    {
      id: 'viewed',
      header: 'VIEWED',
      cell: ({ row }) => (
        <img
          src={row.original.viewed ? viewedEye : notViewedYetEye}
          alt={row.original.viewed ? "viewed" : "Unopened"}
          style={{
            opacity: row.original.viewed ? 1 : 0.5,
            filter: row.original.viewed
              ? "invert(35%) sepia(100%) hue-rotate(90deg) saturate(300%)"
              : "none",
          }}
        />
      ),
    },

    // Trip Details Group
    { accessorKey: 'pickupLocation', header: 'PICKUP' },
    { accessorKey: 'destination', header: 'DESTINATION' },
    { 
      accessorKey: 'departureDate', 
      header: 'DEPARTURE',
      cell: ({ row }) => formatDate(row.original.departureDate)
    },
    { 
      accessorKey: 'returnDate', 
      header: 'RETURN',
      cell: ({ row }) => formatDate(row.original.returnDate)
    },

    // Service Details
    { accessorKey: 'driverName', header: 'DRIVER' },
    { accessorKey: 'busName', header: 'BUS' },

    // Actions
    {
      id: 'edit',
      header: 'EDIT',
      cell: ({ row }) => (
        <Button
          onClick={() => handleEditClick(row.original)}
          className="hover:bg-accent p-2 rounded-md"
        >
          <img src={editIcon} alt="Edit" />
        </Button>
      ),
    },
  ];

  const table = useReactTable({
    data: trips,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination: {
        pageIndex: currentPage - 1,
        pageSize: 8,
      },
    },
    onPaginationChange: (updater) => {
      if (typeof updater === 'function') {
        const newState = updater({ pageIndex: currentPage - 1, pageSize: 8 });
        setCurrentPage(newState.pageIndex + 1);
      }
    },
    // manualPagination: true, // Setting manualPagination to true means we want to use server side pagination
    pageCount: Math.ceil(trips.length / 8),
  });

  return (
    <div className="space-y-4">
      <TableExportButtons 
        table={table} 
        filename={`trips-report-${new Date().toISOString().split('T')[0]}`} 
      />
      
      <div id="table-to-print" className="w-full overflow-x-auto" style={{ fontFamily: "'IBM Plex Mono', monospace" }}>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600&display=swap');
        </style>
        <div className="rounded-md border dark:border-gray-700">
          <div className="max-h-[600px] overflow-auto">
            <table className="w-full table-fixed">
              <thead className="bg-muted/50 dark:bg-gray-800 sticky top-0 z-10">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="h-12 px-4 text-left align-middle font-medium text-muted-foreground dark:text-gray-300 bg-muted/50 dark:bg-gray-800 whitespace-nowrap"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row, index) => (
                    <React.Fragment key={row.id}>
                      <tr 
                        className={`
                          border-b dark:border-gray-700 
                          transition-colors 
                          hover:bg-muted/50 dark:hover:bg-gray-700
                          ${index % 2 === 0 
                            ? 'bg-white dark:bg-gray-900' 
                            : 'bg-gray-50 dark:bg-gray-800'
                          }
                        `}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="p-4 whitespace-normal break-words dark:text-gray-300">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                      <tr 
                        className={`
                          border-b dark:border-gray-700
                          ${index % 2 === 0 
                            ? 'bg-white dark:bg-gray-900' 
                            : 'bg-gray-50 dark:bg-gray-800'
                          }
                        `}
                      >
                        <td colSpan={columns.length} className="p-4 dark:text-gray-300">
                          <div className="font-medium mb-1">Notes:</div>
                          <div className="whitespace-pre-wrap pl-4 border-l-4 border-gray-300 dark:border-gray-600">
                            {row.original.notes || 'No notes available'}
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={columns.length}
                      className="h-24 text-center text-muted-foreground dark:text-gray-400"
                    >
                      No trips found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex items-center justify-between p-4">
          <p className="text-sm text-muted-foreground dark:text-gray-400">
            Showing {table.getState().pagination.pageIndex * 8 + 1} to{' '}
            {Math.min((table.getState().pagination.pageIndex + 1) * 8, trips.length)} of{' '}
            {trips.length} results
          </p>
          <div className="flex items-center space-x-2">
            <button
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.previousPage()}
              className="p-2 hover:bg-accent dark:hover:bg-gray-700 rounded-md disabled:opacity-50"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 18L8 12L16 6"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {Array.from(
              { length: table.getPageCount() },
              (_, index) => index + 1
            ).map((page) => (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                disabled={currentPage === page}
                className={`px-3 py-2 rounded-md ${
                  currentPage === page
                    ? 'bg-accent dark:bg-gray-700'
                    : 'hover:bg-accent dark:hover:bg-gray-700'
                }`}
              >
                {page}
              </button>
            ))}
            <button
              disabled={!table.getCanNextPage()}
              onClick={() => table.nextPage()}
              className="p-2 hover:bg-accent dark:hover:bg-gray-700 rounded-md disabled:opacity-50"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 6L16 12L8 18"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

TripsTable.propTypes = {
  trips: PropTypes.array.isRequired,
  setTripModalData: PropTypes.func.isRequired,
  setShowTripModal: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

export default TripsTable;
