import { useState } from 'react';

export const useAddCustomerModal = () => {
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);

  const openAddCustomerModal = () => {
    setShowAddCustomerModal(true);
  };

  const closeAddCustomerModal = () => {
    setShowAddCustomerModal(false);
  };

  return {
    showAddCustomerModal,
    openAddCustomerModal,
    closeAddCustomerModal
  };
};
