import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "./ui/dialog"; // Adjust the import path based on your project structure
import { Button } from "./ui/button"; // Adjust the import path based on your project structure
import { Input } from "./ui/input"; // Adjust the import path based on your project structure
import InvoiceSection from "./invoicesection"; // Adjust the path based on your project structure
import { customFetch } from "../utils/customFetch.js";
import { getApiRoot } from "../utils/getApiRoot.js";

// Reusable Form Input Component
const FormInput = ({
  label,
  type = "text",
  name,
  value,
  onChange,
  readOnly = false,
  placeholder,
  error,
  selectComponent = false,
  children,
  ...props
}) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
        {label}
      </label>
      {selectComponent ? (
        children
      ) : type === "textarea" ? (
        <textarea
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          className={`mt-1 block w-full border ${
            error ? "border-red-500" : "border-gray-300 dark:border-gray-600"
          } rounded-md shadow-sm p-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100`}
          {...props}
        ></textarea>
      ) : (
        <Input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          className={`mt-1 block w-full ${
            error
              ? "border-red-500"
              : "border-gray-300 dark:border-gray-600"
          } rounded-md shadow-sm p-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100`}
          {...props}
        />
      )}
      {error && (
        <p className="mt-1 text-sm text-red-600 dark:text-red-400">
          {error}
        </p>
      )}
    </div>
  );
};

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  selectComponent: PropTypes.bool,
  children: PropTypes.node,
};

const Modal = ({
  resetTripModal,
  trips,
  setTrips,
  tripModalData,
  setTripModalData,
  showTripModal,
  closeModal,
  openAddCustomerModal,
}) => {
  const [isClientNameFilled, setClientNameFilled] = useState(
    !!tripModalData.firstName && !!tripModalData.lastName
  );
  const [validationErrors, setValidationErrors] = useState({});
  const groupedDriverOptions = useRef(null);

  // Update isClientNameFilled whenever firstName or lastName changes
  useEffect(() => {
    setClientNameFilled(!!tripModalData.firstName && !!tripModalData.lastName);
  }, [tripModalData.firstName, tripModalData.lastName]);

  // Fetch and cache grouped driver options
  const fetchGroupedDriverOptions = async () => {
    if (!groupedDriverOptions.current) {
      const data = await getGroupedDriverOptions();
      groupedDriverOptions.current = data;
    }
    return groupedDriverOptions.current;
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return currentDate.toLocaleString("en-US", options);
  };

  const handleClientSelection = (selection) => {
    if (selection.value === "addCustomer") {
      closeModal();
      openAddCustomerModal();
    } else if (selection.value) {
      setTripModalData((prevData) => ({
        ...prevData,
        ...selection.value,
        quoteDate: getCurrentDate(),
      }));
      setClientNameFilled(true);
    } else {
      setTripModalData((prevData) => {
        const { phoneNumber, companyName, email, ...nonCustomerFields } = prevData;
        return {
          ...nonCustomerFields,
          phoneNumber: "",
          companyName: "",
          email: "",
        };
      });
      setClientNameFilled(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "number" && value < 0) {
      return;
    }
    setTripModalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const notifyDriver = async () => {
    const options = {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tripId: tripModalData.tripId,
        driverEmail: tripModalData.driverEmail,
        driverName: tripModalData.driverName,
        departureDate: tripModalData.departureDate,
        returnDate: tripModalData.returnDate,
      }),
    };
    const response = await customFetch(
      `${getApiRoot()}/api/notifydriver`,
      options
    );
    if (!response.ok) {
      alert(
        "Trip was not fully saved due to a temporary server error. Please try saving again."
      );
    }
  };

  const getBusDrivers = async () => {
    if (tripModalData.tripStatus === "Accepted") {
      const departureISO = new Date(tripModalData.departureDate).toISOString();
      const returnISO = new Date(tripModalData.returnDate).toISOString();
      const options = { credentials: "include" };
      const response = await customFetch(
        `${getApiRoot()}/api/busdrivers?departureDate=${departureISO}&returnDate=${returnISO}`,
        options
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const drivers = await response.json();
      return drivers;
    }
    return [];
  };

  const getDriverOptions = (drivers, type) => {
    const uniqueNames = new Set();
    return drivers
      .filter((driver) => {
        if (
          driver.available &&
          driver[type] &&
          !uniqueNames.has(driver.name)
        ) {
          uniqueNames.add(driver.name);
          return true;
        }
        return false;
      })
      .map((driver) => ({
        label: driver.name,
        value: driver,
      }));
  };

  const getGroupedDriverOptions = async () => {
    const drivers = await getBusDrivers();
    return [
      {
        label: "Primary Drivers",
        options: getDriverOptions(drivers, "primary"),
      },
      {
        label: "Secondary Drivers",
        options: getDriverOptions(drivers, "secondary"),
      },
      {
        label: "Unavailable Drivers",
        options: drivers
          .filter((driver) => !driver.available)
          .map((driver) => ({
            label: driver.name,
            value: driver,
          })),
      },
    ];
  };

  const getBuses = async () => {
    const departureISO = new Date(tripModalData.departureDate).toISOString();
    const returnISO = new Date(tripModalData.returnDate).toISOString();
    const options = { credentials: "include" };
    const response = await customFetch(
      `${getApiRoot()}/api/buses?departureDate=${departureISO}&returnDate=${returnISO}`,
      options
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const buses = await response.json();
    return buses;
  };

  const getBusOptions = (buses, availability) =>
    buses
      .filter((bus) => bus.available === availability)
      .map((bus) => ({
        label: bus.resourceName,
        value: bus,
      }));

  const getGroupedBusOptions = async () => {
    const buses = await getBuses();
    return [
      {
        label: "Available Buses",
        options: getBusOptions(buses, true),
      },
      {
        label: "Unavailable Buses",
        options: getBusOptions(buses, false),
      },
    ];
  };

  // When the save and notify button is clicked by user, the selected bus will be sent to the backend to save to the database and added to the Google Calendar event
  const saveBusSelection = async () => {
    const departureISO = new Date(tripModalData.departureDate).toISOString();
    const returnISO = new Date(tripModalData.returnDate).toISOString();

    try {
      const options = {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          selectedBus: tripModalData?.selectedBus,
          busName: tripModalData?.selectedBus?.resourceName,
          tripId: tripModalData?.tripId,
        }),
      };

      const response = await customFetch(
        `${getApiRoot()}/api/update-bus-in-trip?departureDate=${departureISO}&returnDate=${returnISO}`,
        options
      );

      if (response.ok) {
        resetTripModal();
        closeModal();
      } else {
        alert(
          "Trip was not saved due to a temporary server error. Please try saving again."
        );
      }
    } catch (error) {
      alert(
        "Trip was not saved due to a temporary server error. Please try saving again."
      );
      console.error("Save Bus Selection Error:", error);
    }
  };

  const getClients = async (inputValue) => {
    let clientResults = [];

    if (inputValue) {
      const customFetchOptions = {
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
      };

      let clientResponse = await customFetch(
        `${getApiRoot()}/api/customers/search?lastName=${inputValue}`,
        customFetchOptions
      );

      clientResults = await clientResponse.json();

      if (clientResults.length === 0) {
        clientResponse = await customFetch(
          `${getApiRoot()}/api/customers/search?firstName=${inputValue}`,
          customFetchOptions
        );
        clientResults = await clientResponse.json();
      }

      clientResults = clientResults.map((clientResult) => ({
        label: `${clientResult.firstName} ${clientResult.lastName}`,
        value: clientResult,
      }));
    }

    clientResults = clientResults.filter((clientResult) =>
      clientResult.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    clientResults.push({ label: "Add New Customer", value: "addCustomer" });
    console.log("clientResult", clientResults);
    return clientResults;
  };

  const validateForm = (isDraft) => {
    const errors = {};

    const requiredFields = isDraft
      ? ["firstName", "lastName", "quoteDate", "phoneNumber", "email"]
      : [
          "firstName",
          "lastName",
          "quoteDate",
          "phoneNumber",
          "email",
          "tripStatus",
          "paymentStatus",
          "groupName",
          "groupPhone",
          "groupLeader",
          "numOfPassengers",
          "pickupLocation",
          "destination",
          "departureDate",
          "returnDate",
          "estimatedMileage",
        ];

    if (!isDraft) {
      if (!isClientNameFilled) {
        errors.clientName = "*Required";
      }
    }

    requiredFields.forEach((field) => {
      if (!tripModalData[field]) {
        errors[field] = "*Required";
      }
    });

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleTripSubmission = async (isDraft) => {
    const isValidForm = validateForm(isDraft);

    const departureDate = new Date(tripModalData.departureDate);
    const returnDate = new Date(tripModalData.returnDate);

    if (departureDate > returnDate) {
      alert("Error: Departure Date cannot be after Return Date");
      return;
    }

    const tripStatus = isDraft ? "Draft" : "Pending";

    if (isValidForm) {
      const apiRoot = getApiRoot();
      const method =
        tripStatus === "Draft" ? "PATCH" : tripModalData.tripId ? "PUT" : "POST";
      const customFetchBody = { ...tripModalData };
      if (!customFetchBody.tripStatus) {
        customFetchBody.tripStatus = tripStatus;
      }

      const options = {
        method: method,
        credentials: "include",
        body: JSON.stringify(customFetchBody),
        headers: {
          "Content-Type": "application/json",
        },
      };

      const updatedTrips = trips.map((trip) =>
        trip.tripId === tripModalData.tripId ? tripModalData : trip
      );
      setTrips(updatedTrips);

      try {
        const addTripResponse = await customFetch(`${apiRoot}/api/trips`, options);

        if (addTripResponse.status === 409) {
          alert("A trip with the same details already exists");
        } else if (addTripResponse.status === 200 || addTripResponse.status === 201) {
          resetTripModal();
          closeModal();
        } else {
          alert(
            `${addTripResponse.status} Error ${
              isDraft ? "saving draft" : "adding trip"
            }.`
          );
        }
      } catch (error) {
        console.error(`Error ${isDraft ? "saving draft" : "adding trip"}:`, error);
        alert(
          `An unexpected error occurred while ${
            isDraft ? "saving draft" : "adding trip"
          }.`
        );
      }

      setValidationErrors({});
    } else {
      document.getElementById("validationBanner").style.display = "block";
    }
  };

  const handleSaveDraft = () => {
    handleTripSubmission(true);
  };

  const updateTrip = () => {
    handleTripSubmission(false);
  };

  // Define custom styles for AsyncSelect components
  const customStyles = (validationErrorField) => ({
    control: (base, state) => ({
      ...base,
      backgroundColor: 'var(--bg-color)',
      color: 'var(--text-color)',
      borderColor: validationErrorField ? 'red' : 'var(--border-color)',
      boxShadow: validationErrorField ? '0 0 0 1px red' : base.boxShadow,
      '&:hover': {
        borderColor: validationErrorField ? 'red' : 'var(--border-color)',
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: 'var(--menu-bg-color)',
      color: 'var(--text-color)',
      zIndex: 9999,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? 'var(--selected-bg-color)'
        : state.isFocused
        ? 'var(--hover-bg-color)'
        : 'var(--option-bg-color)',
      color: 'var(--text-color)',
      '&:hover': {
        backgroundColor: 'var(--hover-bg-color)',
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: 'var(--text-color)',
    }),
    input: (base) => ({
      ...base,
      color: 'var(--text-color)',
    }),
    placeholder: (base) => ({
      ...base,
      color: 'var(--text-color)',
    }),
  });

  return (
    <Dialog open={showTripModal} onOpenChange={(open) => !open && closeModal()}>
      <DialogContent className="w-full h-full max-w-none p-6 bg-white dark:bg-gray-800 overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-xl dark:text-white">CHARTER ESTIMATE</DialogTitle>
          <DialogDescription
            id="validationBanner"
            className="text-red-500 mb-4 hidden dark:text-red-400"
          >
            Please enter all required fields to save a trip.
          </DialogDescription>
          <Button
            variant="ghost"
            onClick={closeModal}
            className="absolute top-4 right-4 text-gray-700 dark:text-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </Button>
        </DialogHeader>
        <div className="space-y-6">
          {/* Client's Information Section */}
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <h3 className="text-lg font-medium mb-4 text-gray-800 dark:text-gray-200">
              Client&apos;s Information
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {/* Client's Name */}
              <FormInput
                label="Client's Name"
                name="clientName"
                value={`${tripModalData.firstName || ""} ${tripModalData.lastName || ""}`}
                readOnly={!!tripModalData.tripId}
                error={validationErrors.clientName}
                selectComponent={!tripModalData.tripId}
              >
                {tripModalData.tripId ? (
                  <Input
                    readOnly
                    type="text"
                    name="clientName"
                    placeholder="First & Last"
                    value={`${tripModalData.firstName} ${tripModalData.lastName}`}
                    className="mt-1 block w-full bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                  />
                ) : (
                  <AsyncSelect
                    defaultOptions={[{ label: "Type a customer name", value: "" }]}
                    loadOptions={getClients}
                    onChange={handleClientSelection}
                    classNamePrefix="react-select"
                    styles={customStyles(validationErrors.clientName)}
                  />
                )}
              </FormInput>

              {/* Customer's Phone */}
              <FormInput
                label="Customer's Phone"
                name="phoneNumber"
                type="text"
                value={tripModalData.phoneNumber}
                onChange={handleInputChange}
                readOnly
                placeholder="(XXX) XXX-XXXX"
                error={validationErrors.phoneNumber}
              />

              {/* Quote Date */}
              <FormInput
                label="Quote Date"
                name="quoteDate"
                type="text"
                value={tripModalData.quoteDate}
                onChange={handleInputChange}
                readOnly
                placeholder="MM/DD/YYYY"
                error={validationErrors.quoteDate}
              />

              {/* Customer's Email */}
              <FormInput
                label="Customer's Email"
                name="email"
                type="email"
                value={tripModalData.email}
                onChange={handleInputChange}
                readOnly
                placeholder="example@email.com"
                error={validationErrors.email}
              />

              {/* Company Name */}
              <FormInput
                label="Company Name"
                name="companyName"
                type="text"
                value={tripModalData.companyName}
                onChange={handleInputChange}
                readOnly
                placeholder="Company Name"
              />

              {/* Trip Status */}
              <FormInput
                label="Trip Status"
                name="tripStatus"
                value={tripModalData.tripStatus}
                onChange={handleInputChange}
                selectComponent
              >
                <select
                  name="tripStatus"
                  value={tripModalData.tripStatus}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 rounded-md shadow-sm p-2 text-gray-900 dark:text-gray-100"
                >
                  <option value="Pending">Pending</option>
                  <option value="Draft">Draft</option>
                  <option value="Accepted">Accepted</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Complete">Complete</option>
                </select>
              </FormInput>

              {/* Payment Status */}
              <FormInput
                label="Payment Status"
                name="paymentStatus"
                value={tripModalData.paymentStatus}
                onChange={handleInputChange}
                selectComponent
              >
                <select
                  name="paymentStatus"
                  value={tripModalData.paymentStatus}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 rounded-md shadow-sm p-2 text-gray-900 dark:text-gray-100"
                >
                  <option value="Unpaid">Unpaid</option>
                  <option value="Paid">Paid</option>
                  <option value="Deposit">Deposit</option>
                  <option value="Refunded">Refunded</option>
                </select>
              </FormInput>
            </div>
          </div>

          {/* Trip Details Section */}
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <h3 className="text-lg font-medium mb-4 text-gray-800 dark:text-gray-200">
              Trip Details
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {/* Group Name */}
              <FormInput
                label="Group Name"
                name="groupName"
                value={tripModalData.groupName}
                onChange={handleInputChange}
                placeholder="Group Name"
                error={validationErrors.groupName}
              />

              {/* Group Phone */}
              <FormInput
                label="Group Phone"
                name="groupPhone"
                type="text"
                value={tripModalData.groupPhone}
                onChange={handleInputChange}
                placeholder="(XXX) XXX-XXXX"
                error={validationErrors.groupPhone}
              />

              {/* Group Leader */}
              <FormInput
                label="Group Leader"
                name="groupLeader"
                value={tripModalData.groupLeader}
                onChange={handleInputChange}
                placeholder="Group Leader"
                error={validationErrors.groupLeader}
              />

              {/* Number of Passengers */}
              <FormInput
                label="Number of Passengers"
                name="numOfPassengers"
                type="number"
                value={tripModalData.numOfPassengers}
                onChange={handleInputChange}
                min="0"
                placeholder="Number of Passengers"
                error={validationErrors.numOfPassengers}
              />

              {/* Pickup Location */}
              <FormInput
                label="Pickup Location"
                name="pickupLocation"
                value={tripModalData.pickupLocation}
                onChange={handleInputChange}
                placeholder="Pickup Location"
                error={validationErrors.pickupLocation}
              />

              {/* Destination */}
              <FormInput
                label="Destination"
                name="destination"
                value={tripModalData.destination}
                onChange={handleInputChange}
                placeholder="Destination"
                error={validationErrors.destination}
              />
            </div>
          </div>

          {/* Schedule Section */}
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <h3 className="text-lg font-medium mb-4 text-gray-800 dark:text-gray-200">
              Schedule
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Departure Date & Time */}
              <FormInput
                label="Departure Date & Time"
                name="departureDate"
                value={tripModalData.departureDate}
                error={validationErrors.departureDate}
                selectComponent
              >
                <DatePicker
                  selected={
                    tripModalData.departureDate
                      ? new Date(tripModalData.departureDate)
                      : null
                  }
                  onChange={(date) =>
                    setTripModalData((prevData) => ({
                      ...prevData,
                      departureDate: date,
                    }))
                  }
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  placeholderText="Departure Date & Time"
                  className={`mt-1 block w-full border ${
                    validationErrors.departureDate
                      ? "border-red-500"
                      : "border-gray-300 dark:border-gray-600"
                  } rounded-md shadow-sm p-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100`}
                />
              </FormInput>

              {/* Return Date & Time */}
              <FormInput
                label="Return Date & Time"
                name="returnDate"
                value={tripModalData.returnDate}
                error={validationErrors.returnDate}
                selectComponent
              >
                <DatePicker
                  selected={
                    tripModalData.returnDate
                      ? new Date(tripModalData.returnDate)
                      : null
                  }
                  onChange={(date) =>
                    setTripModalData((prevData) => ({
                      ...prevData,
                      returnDate: date,
                    }))
                  }
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  placeholderText="Return Date & Time"
                  className={`mt-1 block w-full border ${
                    validationErrors.returnDate
                      ? "border-red-500"
                      : "border-gray-300 dark:border-gray-600"
                  } rounded-md shadow-sm p-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100`}
                />
              </FormInput>

              {/* Estimated Mileage */}
              <FormInput
                label="Estimated Mileage"
                name="estimatedMileage"
                type="text"
                value={tripModalData.estimatedMileage}
                onChange={handleInputChange}
                placeholder="Estimated Mileage"
                error={validationErrors.estimatedMileage}
              />

              {/* Other Info */}
              <FormInput
                label="Other Info"
                name="otherInfo"
                type="textarea"
                value={tripModalData.otherInfo}
                onChange={handleInputChange}
                placeholder="Other Info"
              />
            </div>

            {/* Driver and Bus Selection (Conditional) */}
            {tripModalData.tripStatus === "Accepted" && (
              <div className="mt-6 bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
                <h4 className="text-md font-medium mb-4 text-gray-800 dark:text-gray-200">
                  Driver and Bus Selection
                </h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {/* Driver Name */}
                  <FormInput
                    label="Driver Name"
                    name="driverName"
                    value={tripModalData.driverName}
                    error={validationErrors.driverName}
                    selectComponent
                  >
                    <AsyncSelect
                      value={
                        tripModalData.driverName
                          ? {
                              value: tripModalData.driverName,
                              label: tripModalData.driverName,
                            }
                          : null
                      }
                      onChange={(selectedOption) => {
                        if (selectedOption && selectedOption.value) {
                          const { name, email } = selectedOption.value;

                          setTripModalData((prevData) => ({
                            ...prevData,
                            driverName: name,
                            driverEmail: email,
                          }));
                        } else {
                          setTripModalData((prevData) => ({
                            ...prevData,
                            driverName: "",
                            driverEmail: "",
                          }));
                        }
                      }}
                      loadOptions={fetchGroupedDriverOptions}
                      classNamePrefix="react-select"
                      styles={customStyles(validationErrors.driverName)}
                    />
                  </FormInput>

                  {/* Bus Selection */}
                  <FormInput
                    label="Bus"
                    name="selectedBus"
                    value={tripModalData.selectedBus}
                    error={validationErrors.selectedBus}
                    selectComponent
                  >
                    <AsyncSelect
                      value={
                        tripModalData.selectedBus
                          ? {
                              value: tripModalData.selectedBus,
                              label: tripModalData.selectedBus.resourceName,
                            }
                          : null
                      }
                      loadOptions={getGroupedBusOptions}
                      onChange={(selectedOption) => {
                        setTripModalData((prevData) => ({
                          ...prevData,
                          selectedBus: selectedOption?.value,
                          busName: selectedOption?.value?.resourceName,
                        }));
                      }}
                      classNamePrefix="react-select"
                      styles={customStyles(validationErrors.selectedBus)}
                    />
                  </FormInput>
                </div>
              </div>
            )}
          </div>

          {/* Additional Information Section */}
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <h3 className="text-lg font-medium mb-4 text-gray-800 dark:text-gray-200">
              Additional Information
            </h3>
            <div className="space-y-4">
              {/* Notes */}
              <FormInput
                label="Notes"
                name="notes"
                type="textarea"
                value={tripModalData.notes}
                onChange={handleInputChange}
                placeholder="Write here all your notes..."
              />

              {/* Invoice Section */}
              <InvoiceSection
                tripModalData={tripModalData}
                setTripModalData={setTripModalData}
              />
            </div>
          </div>
        </div>

        {/* Modal Footer */}
        <DialogFooter className="flex justify-end space-x-4 mt-6">
          <Button
            variant="outline"
            onClick={() => {
              resetTripModal();
              closeModal();
            }}
            className="text-gray-700 dark:text-gray-300"
          >
            CANCEL
          </Button>
          <Button
            variant="secondary"
            onClick={handleSaveDraft}
            className="bg-yellow-500 hover:bg-yellow-600 text-white"
          >
            SAVE DRAFT
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              updateTrip();
              if (tripModalData.driverName) notifyDriver();
              if (tripModalData.selectedBus) saveBusSelection();
            }}
            className="bg-blue-600 hover:bg-blue-700 text-white"
          >
            {tripModalData.driverName
              ? "SAVE AND NOTIFY DRIVER"
              : "SAVE AND SEND QUOTE"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

Modal.propTypes = {
  resetTripModal: PropTypes.func.isRequired,
  trips: PropTypes.array.isRequired,
  setTrips: PropTypes.func.isRequired,
  tripModalData: PropTypes.object.isRequired,
  setTripModalData: PropTypes.func.isRequired,
  showTripModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openAddCustomerModal: PropTypes.func.isRequired,
};

export default Modal;
