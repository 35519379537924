import React from 'react';
import CustomersTable from './tables/customers-table';
import Sidebar from './sidebar';
import { useAuth } from '../context/AuthContext';
import AddCustomerModal from './addcustomermodal';
import { useAddCustomerModal } from '../utils/modalHelpers';

const Customers = () => {
  const { authorization } = useAuth();
  const { showAddCustomerModal, openAddCustomerModal, closeAddCustomerModal } = useAddCustomerModal();

  return (
    <div className="flex h-screen">
      <Sidebar authorization={authorization} />
      <main className="flex-1 overflow-y-auto">
        <div className="container mx-auto py-10">
          <div className="flex items-center justify-between">
            <h2 className="text-3xl font-bold tracking-tight">Customers</h2>
            <button
              onClick={openAddCustomerModal}
              className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
            >
              ADD CUSTOMER
            </button>
          </div>
          <div className="mt-8">
            <CustomersTable />
          </div>
        </div>
      </main>

      {/* Render the Add Customer Modal */}
      {showAddCustomerModal && (
        <AddCustomerModal
          showModal={showAddCustomerModal}
          closeModal={closeAddCustomerModal}
        />
      )}
    </div>
  );
};

export default Customers;
