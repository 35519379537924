/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { customFetch } from "../utils/customFetch.js";
import { useNavigate } from "react-router-dom";
import { getApiRoot } from "../utils/getApiRoot.js";
import { extractTripUrlData } from "../utils/getUrlData.js";
import EmptyCircle from "./images/EmptyCircle.svg";
import FilledCircle from "./images/FilledCircle.svg";
import fullLogo from "./images/fullLogo.png";
import "./non-contract.css";
import { useLocation } from 'react-router-dom';

function NonContract() {
  const navigator = useNavigate();

  const { tripId, token } = extractTripUrlData();
  token && (document.cookie = "nonContract=" + token);
  const [selectedButton, setSelectedButton] = useState("partNowPartLaterBtn");
  const [acceptDate, setAcceptDate] = useState("");
  const [tripData, setTripData] = useState(null);          
  const [signature, setSignature] = useState("");

  const subTotal = tripData?.invoiceItems.reduce((acc, item) => acc + item.totalPrice, 0);
  const salesTax = (subTotal * (tripData?.taxRate || 0)) / 100;
  const grandTotal = subTotal + salesTax;

  const buttons = [
    {
      id: "partNowPartLaterBtn",
      buttonText: "Pay part now, part later",
      depositText:
        `Pay  $${(grandTotal/2).toFixed(2)} now as a 50% up front payment, and pay the remaining $${(grandTotal/2).toFixed(2)} before the day of your trip.`
    },
    {
      id: "payInFullBtn",
      buttonText: "Pay in full",
      depositText: "Pay the total and you are all set"
    }
  ];

  useEffect(() => {
    console.log("customFetching trip data...");
    const customFetchOptions = {
      credentials: "include",
      headers: {
        "content-type": "application/json"
      }
    };
    const customFetchTripData = async () => {
      try {
        const response = await customFetch(`${getApiRoot()}/api/non-contract/${tripId}`, {
          credentials: "include",
          customFetchOptions
        });
        if (response.ok) {
          const data = await response.json();
          data.departureDate = new Date(data.departureDate); //convert string to Date
          data.returnDate = new Date(data.returnDate); //convert string to Date
          setTripData(data);
          console.log("Trip data:", data);

          // Mark the trip as viewed -- to the database
          const updateResponse = await customFetch(`${getApiRoot()}/api/non-contract/${tripId}`, {
            method: "PATCH",
            credentials: "include",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ viewed: true })
          });
          if (!updateResponse.ok) {
            console.error("Error updating 'viewed' status:", updateResponse.statusText);
          }
        } else {
          console.error("Error customFetching trip data:", response.statusText);
        }
      } catch (error) {
        console.error("Error customFetching trip data:", error);
      }
    };

    customFetchTripData();
  }, [tripId]);


  useEffect(()=>{
    if(tripData?.firstHalfInvoiceLink || tripData?.secondHalfInvoiceLink || tripData?.entireInvoiceLink){
      redirectToPaymentLinks({data:tripData});
    }
    if(tripData?.contract){
      setSelectedButton("payInFullBtn")
    }
  },[tripData]);

  const handleButtonClick = (buttonId) => {
    console.log(`Button clicked: ${buttonId}`);
    setSelectedButton(buttonId);
    if (buttonId === "someButtonId") {
      setTripId("newTripId");
    }
  };

  const handleAcceptAndPay = async () => {
    if (!signature || !acceptDate) {
      alert("Please provide both signature and date before accepting and paying.");
      return;
    }

    try {
      const apiRoot = getApiRoot();
      const response = await customFetch(
        `${apiRoot}/api/non-contract/${tripId}/accept?payButton=${selectedButton}`,
        {
          method: "PATCH",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            tripId,
            phoneNumber: tripData.phoneNumber,
            tripStatus: "Accepted",
            signature,
            acceptDate
          })
        }
      );

      if (response.ok) {
        alert("Please pay on the next page to secure your reservation.");
        // Perform any additional actions or state updates if needed
        const data = await response.json();
        redirectToPaymentLinks({data});
        // const orderInfo = `$clientName=${encodeURIComponent(
        //   tripData?.firstName
        // )}$subTotal=${encodeURIComponent(subTotal)}$tax=${encodeURIComponent(
        //   salesTax
        // )}$total=${encodeURIComponent(grandTotal)}`;

        // let url = `/non-contract/payment/#`;

        // if (selectedButton === "partNowPartLaterBtn") {
        //   url += `selectedButton=${selectedButton}&link1=${encodeURIComponent(
        //     data.firstHalfInvoiceLink
        //   )}&link2=${encodeURIComponent(data.secondHalfInvoiceLink)}&${orderInfo}`;
        // } else {
        //   url += `selectedButton=${selectedButton}&link=${encodeURIComponent(
        //     data.entireInvoiceLink
        //   )}&${orderInfo}`;
        // }

        // window.location.href = url;
      } else {
        console.error("Error accepting the trip:", response.statusText);
        alert("An error occurred while accepting and paying for the trip.");
      }
    } catch (error) {
      console.error("Error accepting the trip:", error);
      alert("An unexpected error occurred while accepting and paying for the trip.");
    }
  };

  const redirectToPaymentLinks=({data})=>{
    navigator('/payment',{state:{selectedButton,link1:data.firstHalfInvoiceLink,link2:data.secondHalfInvoiceLink,link:data.entireInvoiceLink,subTotal,tax:salesTax,total:grandTotal,clientName:tripData?.firstName}});
  }

  // Determine which buttons to display
  const availableButtons = tripData?.contract
    ? buttons.filter((button) => button.id !== "partNowPartLaterBtn")
    : buttons;

  return (
    <>
      <div className="titleOrLogo">
        <img src={fullLogo} alt="Mustang Transportation logo" />
      </div>
      <div className="non-contract-body">
        <div className="LeftSide">
          <div className="Instructions">
            REVIEW {">"} SIGN {">"} PAY
          </div>

          <div className="NCmodal-body">
            <div className="bigSection2">
              <h2 className="sectionTitle">Charter Estimate</h2>
              <h2 className="sectionTitle">Payment service is provided by Intuit Payments Inc.</h2>
              <div className="row2">
                <label>
                  Client&rsquo;s Information
                  <input
                    readOnly={true}
                    type="text"
                    name="Client's Name"
                    placeholder="Client's Name"
                    value={tripData?.firstName || ""}
                    onChange={(e) => {}}
                  />
                </label>
                <label>
                  Quote Number
                  <input
                    readOnly={true}
                    type="text"
                    name="Quote Number"
                    placeholder="1111"
                    value={tripData?.tripId || ""}
                    onChange={(e) => {}}
                  />
                </label>
              </div>
              <div className="row2">
                <label>
                  Customer&rsquo;s Phone
                  <input
                    readOnly={true}
                    type="text"
                    name="Customer's Phone"
                    placeholder="(XXX) XXX XXXX)"
                    value={tripData?.phoneNumber || ""}
                    onChange={() => {}}
                  />
                </label>
                <label>
                  Quote Date
                  <input
                    readOnly={true}
                    type="text"
                    name="Quote Date"
                    placeholder="MM/DD/YYYY"
                    value={tripData?.quoteDate || ""}
                    onChange={() => {}}
                  />
                </label>
              </div>
              <div className="row2">
                <label>
                  Customer&rsquo;s Email:
                  <input
                    readOnly={true}
                    type="text"
                    name="email"
                    placeholder="example@email.com"
                    value={tripData?.email || ""}
                    onChange={() => {}}
                    onBlur={() => {
                      console.log("Email lost focus");
                    }}
                  />
                </label>
                <label>
                  Company Name
                  <input
                    readOnly={true}
                    type="text"
                    name="Company Name"
                    placeholder="Company Name"
                    value={tripData?.companyName || ""}
                    onChange={() => {}}
                  />
                </label>
              </div>
            </div>
            <div className="bigSection2">
              <h2 className="sectionTitle">Trip Details</h2>
              <div className="row2">
                <label>
                  Group Name
                  <input
                    readOnly={true}
                    type="text"
                    name="Group Name"
                    placeholder="Group Name"
                    value={tripData?.groupName || ""}
                    onChange={() => {}}
                  />
                </label>
                <label>
                  Group Phone
                  <input
                    readOnly={true}
                    type="text"
                    name="Group Phone"
                    placeholder="(XXX) XXX XXXX)"
                    value={tripData?.groupPhone || ""}
                    onChange={() => {}}
                  />
                </label>
              </div>
              <div className="row2">
                <label>
                  Group Leader
                  <input
                    readOnly={true}
                    type="text"
                    name="Group Leader"
                    placeholder="Group Leader"
                    value={tripData?.groupLeader || ""}
                    onChange={() => {}}
                  />
                </label>
                <label>
                  Number of Passengers
                  <input
                    readOnly={true}
                    type="number"
                    name="Number of Passengers"
                    placeholder="Number of Passengers"
                    value={tripData?.numOfPassengers || ""}
                    onChange={() => {}}
                  />
                </label>
              </div>
              <div className="row2">
                <label>
                  Pickup Location
                  <input
                    readOnly={true}
                    type="text"
                    name="Pickup Location"
                    placeholder="Pickup Location"
                    value={tripData?.pickupLocation || ""}
                    onChange={() => {}}
                  />
                </label>
                <label>
                  Destination
                  <input
                    readOnly={true}
                    type="text"
                    name="Destination"
                    placeholder="Destination"
                    value={tripData?.destination || ""}
                    onChange={() => {}}
                  />
                </label>
              </div>

              <div className="row2 ">
                <label>
                  Departure Date
                  <div className="inputs">
                    <input
                      readOnly={true}
                      type="text"
                      name="Departure Date"
                      placeholder="MM/DD/YYYY"
                      className="inputTime"
                      value={tripData ? new Date(tripData.departureDate).toDateString() : ""}
                      onChange={(e) => {}}
                    />

                    <input
                      readOnly={true}
                      type="text"
                      name="hour"
                      placeholder="HH:MM:SS TZ"
                      className="inputTime"
                      value={
                        tripData
                          ? new Date(tripData.departureDate).toLocaleTimeString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                              timeZoneName: "short"
                            })
                          : ""
                      }
                      onChange={(e) => {}}
                    />
                  </div>
                </label>

                <label>
                  Return Date
                  <div className="inputs">
                    <input
                      readOnly={true}
                      type="text"
                      name="Return Date"
                      placeholder="MM/DD/YYYY"
                      className="inputTime"
                      value={tripData ? new Date(tripData.returnDate).toDateString() : ""}
                      onChange={(e) => {}}
                    />
                    <input
                      readOnly={true}
                      type="text"
                      name="hour"
                      placeholder="HH:MM:SS TZ"
                      className="inputTime"
                      value={
                        tripData
                          ? new Date(tripData.returnDate).toLocaleTimeString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                              timeZoneName: "short"
                            })
                          : ""
                      }
                      onChange={(e) => {}}
                    />
                  </div>
                </label>
              </div>
              <div className="row2">
                <label>
                  Estimated Mileage
                  <input
                    readOnly={true}
                    type="text"
                    name="Estimated Mileage"
                    placeholder="Estimated Mileage"
                    value={tripData?.estimatedMileage || ""}
                    onChange={(e) => {}}
                  />
                </label>
                <label className="hiddens">
                  This is a hidden input for making the form look good
                  <input
                    disabled={true}
                    type="text"
                    name="Estimated Mileage"
                    className="hiddens"
                    placeholder="Estimated Mileage"
                    value={tripData?.estimatedMileage || ""}
                    onChange={(e) => {}}
                  />
                </label>
              </div>
            </div>
            <div className="bigSection2">
              <h2 className="">Notes</h2>
              <div className="notes">
                <textarea
                  name="notes"
                  className="textarea"
                  value={tripData?.notes || ""}
                  onChange={() => {}}
                />
              </div>
            </div>

            <div className="invoice-section">
              <div className="invoice-header">
                <div>Item</div>
                <div>Description</div>
                <div>Quantity</div>
                <div>Unit Price</div>
                <div>Total Price</div>
              </div>
              {/* Iterate over invoice items and display them */}
              {tripData?.invoiceItems?.map((item, index) => (
                <div key={index} className="invoice-item">
                  <div>{item.item}</div>
                  <div>{item.description}</div>
                  <div>{item.quantity}</div>
                  <div>{item.unitPrice}</div>
                  <div>{item.totalPrice}</div>
                </div>
              ))}
              <div className="invoice-footer">
                <div>Subtotal $ {subTotal}</div>
              </div>
              <div className="invoice-footer">
                <div>Tax Rate </div>
                <div>
                  <input
                    type="number"
                    readOnly
                    placeholder="Tax Rate (%)"
                    value={salesTax || 0}
                    onChange={() => {}}
                  />
                  %
                </div>
              </div>
              <div className="invoice-footer">
                <div>Sales Tax $ {salesTax}</div>
                {/* Calculate sales tax manually using the subtotal and tax rate */}
              </div>
              <div className="invoice-footer">
                <div>Grand Total $ {grandTotal}</div>

                {/* Calculate grand total manually using the subtotal and sales tax */}
              </div>
            </div>
          </div>

          <div className="signature">
            <div>
              <div className="divsLabelSignature">
                <label className="labelSignature">
                  Signature
                  <input
                    type="text"
                    name="Signature"
                    placeholder="Please, sign here"
                    className="labelSignatureInput"
                    value={signature}
                    onChange={(e) => setSignature(e.target.value)}
                  />
                </label>
                <label className="labelSignature">
                  Date
                  <input
                    type="text"
                    name="Date"
                    className="dateLabel"
                    placeholder="month day, year "
                    value={acceptDate}
                    onChange={(e) => setAcceptDate(e.target.value)}
                  />
                  {/* <button
                    className="calendarButton"
                    type="button"
                    onClick={() =>
                      setAcceptDate(new Date().toLocaleDateString())
                    }
                  >
                    🗓️
                  </button> */}
                </label>
              </div>
              <div className="disclaimerText">
                Please give 48 hours notice prior to departure time for cancellations; failure to do
                so will result in a cancellation fee. Mileage is estimated, in the event that trip
                goes 50 miles further than this estimate Mustang Transportation reserves the right
                to bill for the additional miles on the final invoice. Customer is responsible for
                any damage to bus caused by passengers. Customer will be charged a cleaning fee of
                up to $500 if passengers make and leave a mess on the bus.
              </div>
            </div>
            <div className="companyAddress">
              965 E Lincoln Rd Ste B Idaho Falls, ID 83401 | (20B) 243-3010 | www.mustangbus.com
            </div>
          </div>

          <div className="paymentNowLaterButtons">
            {availableButtons.map((button) => (
              <Button
                key={button.id}
                id={button.id}
                selectedButton={selectedButton}
                handleButtonClick={handleButtonClick}
                buttonText={button.buttonText}
                depositText={button.depositText}
              />
            ))}
          </div>

          <div className="sendButtons">
            <button>CANCEL</button>
            <button
              onClick={() => {
                handleAcceptAndPay();
              }}>
              ACCEPT AND PAY
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default NonContract;

const Button = ({ id, selectedButton, handleButtonClick, buttonText, depositText, imgSrc }) => (
  <button
    id={id}
    className={selectedButton === id ? "selected bigButton" : "bigButton"}
    onClick={() => handleButtonClick(id)}>
    <div className="buttonText">
      <div className="depositButton">{buttonText}</div>
      <div className="depositText">{depositText}</div>
    </div>
    <img src={selectedButton === id ? FilledCircle : EmptyCircle} alt="" className="circleDot" />
  </button>
);


