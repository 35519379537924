import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { customFetch } from '../../utils/customFetch';
import { getApiRoot } from '../../utils/getApiRoot';
import EditCustomerModal from '../modals/edit-customer-modal';

const CustomersTable = () => {
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [lastFetchTime, setLastFetchTime] = useState(null);

  const searchCustomers = useCallback(async (force = false) => {
    // Don't fetch if we've fetched within the last second (debounce)
    if (!force && lastFetchTime && Date.now() - lastFetchTime < 1000) {
      console.log('Skipping fetch - too soon since last fetch');
      return;
    }

    setIsLoading(true);
    setLastFetchTime(Date.now());

    try {
      const apiRoot = getApiRoot();
      const timestamp = Date.now(); // Add timestamp to prevent caching
      const endpoint = `${apiRoot}/api/customers/all?_=${timestamp}`;
      console.log('Fetching customers from:', endpoint);

      const response = await customFetch(
        endpoint,
        {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache'
          }
        },
        (error) => {
          console.error('CustomFetch error:', error);
          setError('Failed to fetch customers. Please try again later.');
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log('Raw customer data:', data);
        
        // Transform data to ensure consistent ID field
        const transformedCustomers = data.map((customer, index) => ({
          ...customer,
          id: customer.id || customer._id || `temp-id-${index}`, // Generate a temporary ID if missing
        }));

        console.log('Transformed customers:', transformedCustomers);
        setCustomers(transformedCustomers);
        setError(null);
      } else {
        console.error('Failed to fetch customers:', response.status, response.statusText);
        try {
          const errorData = await response.json();
          console.error('Error response:', errorData);
          setError(errorData.message || 'Failed to fetch customers. Please try again later.');
        } catch (e) {
          setError('Failed to fetch customers. Please try again later.');
        }
      }
    } catch (error) {
      console.error('Error searching customers:', error);
      setError('Failed to fetch customers. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [lastFetchTime]);

  const handleEditClick = useCallback((customer) => {
    console.log('Edit clicked for customer:', customer);
    setEditingCustomer(customer);
    setIsEditModalOpen(true);
  }, []);

  const handleEditClose = useCallback(() => {
    console.log('Closing edit modal');
    setIsEditModalOpen(false);
    setEditingCustomer(null);
  }, []);

  const handleUpdateSuccess = useCallback(() => {
    console.log('Customer updated, refreshing table');
    searchCustomers(true); // Force refresh after update
  }, [searchCustomers]);

  const columns = useMemo(() => [
    {
      accessorKey: 'firstName',
      header: 'First Name',
    },
    {
      accessorKey: 'lastName',
      header: 'Last Name',
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      accessorKey: 'phoneNumber',
      header: 'Phone',
    },
    {
      accessorKey: 'companyName',
      header: 'Company',
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <button
          onClick={() => handleEditClick(row.original)}
          className="px-3 py-1 text-sm border rounded-md hover:bg-muted/50"
        >
          Edit
        </button>
      ),
    },
  ], [handleEditClick]);

  useEffect(() => {
    searchCustomers(true); // Initial fetch
    
    const handleCustomerAdded = () => {
      console.log('Customer added event received');
      searchCustomers(true); // Force refresh on customer added
    };

    window.addEventListener('customerAdded', handleCustomerAdded);
    
    return () => {
      window.removeEventListener('customerAdded', handleCustomerAdded);
    };
  }, []);

  const table = useReactTable({
    data: customers,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  if (error) {
    return (
      <div className="rounded-md border p-4 text-center text-red-500">
        {error}
        <button
          onClick={() => searchCustomers(true)}
          className="ml-4 px-3 py-1 text-sm border rounded-md hover:bg-red-50"
        >
          Retry
        </button>
      </div>
    );
  }

  if (isLoading && !customers.length) {
    return (
      <div className="rounded-md border p-4 text-center">
        Loading customers...
      </div>
    );
  }

  return (
    <div className="rounded-md border">
      <div className="max-h-[600px] overflow-auto">
        <table className="w-full">
          <thead className="bg-muted/50 sticky top-0">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="h-12 px-4 text-left align-middle font-medium text-muted-foreground"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getPaginationRowModel().rows?.length ? (
              table.getPaginationRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="border-b transition-colors hover:bg-muted/50"
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="p-4">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className="h-24 text-center text-muted-foreground"
                >
                  No customers found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-between p-4 border-t">
        <div className="flex items-center gap-2">
          <button
            className="px-4 py-2 text-sm border rounded-md hover:bg-muted/50 disabled:opacity-50"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 text-sm border rounded-md hover:bg-muted/50 disabled:opacity-50"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </button>
        </div>
        <span className="text-sm text-muted-foreground">
          Page {table.getState().pagination.pageIndex + 1} of{' '}
          {table.getPageCount()}
        </span>
      </div>

      {editingCustomer && (
        <EditCustomerModal
          customer={editingCustomer}
          isOpen={isEditModalOpen}
          onClose={handleEditClose}
          onUpdate={handleUpdateSuccess}
        />
      )}
    </div>
  );
};

export default CustomersTable;
