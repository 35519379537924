import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FileDown, FileSpreadsheet, FileText } from 'lucide-react';

const TableExportButtons = ({ table, filename = 'trip-report' }) => {
  const formatDate = (dateString) => {
    if (!dateString || dateString === 'Not Set' || dateString === 'Invalid Date') {
      return 'Not Set';
    }
    return new Date(dateString).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    });
  };

  const getStatusColor = (status) => {
    const colors = {
      'Pending': [255, 152, 0],    // Orange
      'Completed': [76, 175, 80],  // Green
      'Cancelled': [244, 67, 54],  // Red
      'Unpaid': [244, 67, 54],     // Red
      'Paid': [76, 175, 80]        // Green
    };
    return colors[status] || [158, 158, 158]; // Gray default
  };

  const exportToPDF = () => {
    const doc = new jsPDF('l', 'pt', 'a4');
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 40;

    // Helper function for text styling
    const addStyledText = (text, x, y, opts = {}) => {
      const {
        fontSize = 10,
        fontStyle = 'normal',
        color = [0, 0, 0],
        align = 'left'
      } = opts;

      doc.setFontSize(fontSize);
      doc.setFont('helvetica', fontStyle);
      doc.setTextColor(...color);
      doc.text(text, x, y, { align });
      return doc.getTextDimensions(text).h + 5;
    };

    // Add header banner - changed to black
    doc.setFillColor(0, 0, 0);
    doc.rect(0, 0, pageWidth, 80, 'F');

    // Add main title
    addStyledText('Trip Report', margin, 45, {
      fontSize: 28,
      fontStyle: 'bold',
      color: [255, 255, 255]
    });

    // Add timestamp
    addStyledText(
      `Generated on ${new Date().toLocaleString()}`,
      margin,
      70,
      { fontSize: 12, color: [220, 220, 220] }
    );

    let yPos = 120;

    // Add summary statistics
    const rows = table.getRowModel().rows;
    const tripsByStatus = rows.reduce((acc, row) => {
      const status = row.getValue('tripStatus') || 'Undefined';
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    const paymentStats = rows.reduce((acc, row) => {
      const status = row.getValue('paymentStatus') || 'Undefined';
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    // Summary Box
    doc.setDrawColor(200, 200, 200);
    doc.setFillColor(245, 245, 245);
    doc.roundedRect(margin, yPos - 15, 300, 120, 3, 3, 'F');

    addStyledText('Trip Summary', margin + 15, yPos, {
      fontSize: 16,
      fontStyle: 'bold',
      color: [60, 60, 60]
    });

    yPos += 25;

    // Add trip statistics
    addStyledText(`Total Trips: ${rows.length}`, margin + 15, yPos);
    yPos += 20;

    Object.entries(tripsByStatus).forEach(([status, count]) => {
      const text = `${status} Trips: ${count}`;
      doc.setTextColor(...getStatusColor(status));
      doc.text(text, margin + 15, yPos);
      yPos += 15;
    });

    yPos += 10;
    addStyledText('Payment Status', margin + 15, yPos, {
      fontSize: 12,
      fontStyle: 'bold'
    });
    yPos += 15;

    Object.entries(paymentStats).forEach(([status, count]) => {
      const text = `${status}: ${count}`;
      doc.setTextColor(...getStatusColor(status));
      doc.text(text, margin + 15, yPos);
      yPos += 15;
    });

    yPos += 20;

    // Main trips table with notes
    const processRowsWithNotes = rows.flatMap(row => {
      const mainRow = [
        row.getValue('tripId') || 'N/A',
        `${row.original.firstName || ''} ${row.original.lastName || ''}`.trim() || 'N/A',
        row.getValue('companyName') || 'N/A',
        row.getValue('tripStatus') || 'N/A',
        row.getValue('paymentStatus') || 'N/A',
        row.original.viewed ? 'Yes' : 'No',
        row.getValue('pickupLocation') || 'N/A',
        row.getValue('destination') || 'N/A',
        formatDate(row.getValue('departureDate')),
        formatDate(row.getValue('returnDate')),
        row.getValue('driverName') || 'Not Assigned',
        row.getValue('busName') || 'Not Assigned'
      ];

      // Add notes row
      const notes = row.original.notes || 'No notes available';
      return [
        mainRow,
        [{
          content: `Notes: ${notes}`,
          colSpan: 12,
          styles: {
            fontStyle: 'italic',
            textColor: [100, 100, 100],
            fillColor: [250, 250, 250],
            cellPadding: 8,
            overflow: 'linebreak'
          }
        }]
      ];
    });

    doc.autoTable({
      head: [
        [
          { content: 'Trip Details', colSpan: 12, styles: {
            halign: 'center',
            fillColor: [0, 0, 0], // Changed to black
            textColor: [255, 255, 255],
            fontSize: 14
          }}
        ],
        [
          'Quote #',
          'Client',
          'Company',
          'Status',
          'Payment',
          'Viewed',
          'Pickup',
          'Destination',
          'Departure',
          'Return',
          'Driver',
          'Bus'
        ]
      ],
      body: processRowsWithNotes,
      startY: yPos,
      tableWidth: 'wrap',
      styles: {
        fontSize: 9,
        cellPadding: 6,
        lineColor: [200, 200, 200],
        lineWidth: 0.1,
        valign: 'top',
        overflow: 'linebreak'
      },
      headStyles: {
        fillColor: [0, 0, 0], // Changed to black
        textColor: 255,
        fontSize: 10,
        fontStyle: 'bold',
        halign: 'center'
      },
      columnStyles: {
        0: { cellWidth: 70 },  // Quote #
        1: { cellWidth: 61 },  // Client
        2: { cellWidth: 70 },  // Company
        3: { cellWidth: 53 },  // Status
        4: { cellWidth: 53 },  // Payment
        5: { cellWidth: 44 },  // Viewed
        6: { cellWidth: 70 },  // Pickup
        7: { cellWidth: 70 },  // Destination
        8: { cellWidth: 79 },  // Departure
        9: { cellWidth: 79 },  // Return
        10: { cellWidth: 61 }, // Driver
        11: { cellWidth: 53 }  // Bus
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245]
      },
      margin: { left: margin, right: margin },
      didDrawPage: (data) => {
        // Recreate header on new pages - changed to black
        doc.setFillColor(0, 0, 0);
        doc.rect(0, 0, pageWidth, 40, 'F');

        // Add running header
        doc.setTextColor(255, 255, 255);
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('Trip Report', margin, 25);

        // Add page number
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.setTextColor(100, 100, 100);
        const pageText = `Page ${doc.internal.getCurrentPageInfo().pageNumber} of ${doc.internal.getNumberOfPages()}`;
        doc.text(pageText, pageWidth - margin - doc.getTextWidth(pageText), pageHeight - 20);

        // Add confidential watermark
        doc.setTextColor(200, 200, 200);
        doc.setFontSize(10);
        doc.text('CONFIDENTIAL', pageWidth / 2, pageHeight - 20, { align: 'center' });

        // Add timestamp in footer
        doc.setTextColor(150, 150, 150);
        doc.setFontSize(8);
        doc.text(
          `Generated: ${new Date().toLocaleString()}`,
          margin,
          pageHeight - 20
        );
      },
      didParseCell: (data) => {
        // Style specific cells based on content
        if (data.section === 'body') {
          const value = data.cell.raw;

          // Center align certain columns
          if ([3, 4, 5].includes(data.column.index)) {
            data.cell.styles.halign = 'center';
          }

          // Color code status and payment cells
          if (data.column.index === 3 || data.column.index === 4) {
            data.cell.styles.textColor = getStatusColor(value);
            data.cell.styles.fontStyle = 'bold';
          }

          // Style dates
          if (data.column.index === 8 || data.column.index === 9) {
            if (value === 'Not Set') {
              data.cell.styles.textColor = [169, 169, 169];
            }
          }

          // Style unassigned values
          if (value === 'Not Assigned' || value === 'N/A') {
            data.cell.styles.textColor = [169, 169, 169];
            data.cell.styles.fontStyle = 'italic';
          }

          // Style notes row
          if (data.row.section === 'body' && data.cell.colSpan === 12) {
            data.cell.styles.fontStyle = 'italic';
            data.cell.styles.textColor = [100, 100, 100];
            data.cell.styles.fillColor = [250, 250, 250];
            data.cell.styles.cellPadding = 8;
          }
        }
      }
    });

    doc.save(`${filename}.pdf`);
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    // Prepare data for export
    const exportData = table.getRowModel().rows.map(row => {
      return {
        'Quote #': row.getValue('tripId') || 'N/A',
        'Client': `${row.original.firstName || ''} ${row.original.lastName || ''}`.trim() || 'N/A',
        'Company': row.getValue('companyName') || 'N/A',
        'Status': row.getValue('tripStatus') || 'N/A',
        'Payment': row.getValue('paymentStatus') || 'N/A',
        'Viewed': row.original.viewed ? 'Yes' : 'No',
        'Pickup': row.getValue('pickupLocation') || 'N/A',
        'Destination': row.getValue('destination') || 'N/A',
        'Departure': formatDate(row.getValue('departureDate')),
        'Return': formatDate(row.getValue('returnDate')),
        'Driver': row.getValue('driverName') || 'Not Assigned',
        'Bus': row.getValue('busName') || 'Not Assigned',
        'Notes': row.original.notes || 'No notes available'
      };
    });

    const ws = XLSX.utils.json_to_sheet(exportData);

    // Style the worksheet
    const colWidths = [
      { wch: 15 },  // Quote #
      { wch: 20 },  // Client
      { wch: 20 },  // Company
      { wch: 12 },  // Status
      { wch: 12 },  // Payment
      { wch: 8 },   // Viewed
      { wch: 20 },  // Pickup
      { wch: 20 },  // Destination
      { wch: 22 },  // Departure
      { wch: 22 },  // Return
      { wch: 15 },  // Driver
      { wch: 15 },  // Bus
      { wch: 40 }   // Notes
    ];

    ws['!cols'] = colWidths;

    XLSX.utils.book_append_sheet(wb, ws, 'Trips');

    // Generate Excel file
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(
      new Blob([excelBuffer], { type: 'application/octet-stream' }), 
      `${filename}.xlsx`
    );
  };

  const exportToCSV = () => {
    const headers = [
      'Quote #',
      'Client',
      'Company',
      'Status',
      'Payment',
      'Viewed',
      'Pickup',
      'Destination',
      'Departure',
      'Return',
      'Driver',
      'Bus',
      'Notes'
    ];

    const rows = table.getRowModel().rows.map(row => [
      row.getValue('tripId') || 'N/A',
      `${row.original.firstName || ''} ${row.original.lastName || ''}`.trim() || 'N/A',
      row.getValue('companyName') || 'N/A',
      row.getValue('tripStatus') || 'N/A',
      row.getValue('paymentStatus') || 'N/A',
      row.original.viewed ? 'Yes' : 'No',
      row.getValue('pickupLocation') || 'N/A',
      row.getValue('destination') || 'N/A',
      formatDate(row.getValue('departureDate')),
      formatDate(row.getValue('returnDate')),
      row.getValue('driverName') || 'Not Assigned',
      row.getValue('busName') || 'Not Assigned',
      row.original.notes || 'No notes available'
    ]);

    const csvContent = [
      headers.join(','),
      ...rows.map(row => row.map(cell => {
        // Escape commas and quotes in cell values
        if (cell === null || cell === undefined) return '';
        const stringCell = String(cell);
        if (stringCell.includes(',') || stringCell.includes('"') || stringCell.includes('\n')) {
          return `"${stringCell.replace(/"/g, '""')}"`;
        }
        return stringCell;
      }).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${filename}.csv`);
  };

  return (
    <div className="flex gap-2 mb-4 justify-end">
      <button 
        onClick={exportToPDF}
        className="px-3 py-1.5 bg-black text-white rounded hover:bg-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50 text-sm flex items-center gap-1"
      >
        <FileText size={16} />
        PDF
      </button>
      <button 
        onClick={exportToExcel}
        className="px-3 py-1.5 bg-green-500 text-white rounded hover:bg-green-600 transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 text-sm flex items-center gap-1"
      >
        <FileSpreadsheet size={16} />
        Excel
      </button>
      <button 
        onClick={exportToCSV}
        className="px-3 py-1.5 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 text-sm flex items-center gap-1"
      >
        <FileDown size={16} />
        CSV
      </button>
    </div>
  );
};

export default TableExportButtons;
