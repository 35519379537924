import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { Avatar, AvatarFallback, AvatarImage } from '../components/ui/avatar';
import logo from "./images/logoWhite.png";

import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from '../components/ui/dropdown-menu';
import { Globe, LogOut, Settings, Users, ChevronRight } from 'lucide-react';
import { useTheme } from '../components/theme-provider';

const Sidebar = ({ authorization }) => {
  const navigate = useNavigate();
  const { theme, setTheme } = useTheme();
  const userName = authorization?.emailAddress?.split("@")[0] || "User";
  const userEmail = authorization?.emailAddress || "user@example.com";
  const [activeItem, setActiveItem] = useState('trips');

  const handleLogout = () => {
    navigate('/logout');
  };

  const NavButton = ({ icon: Icon, label, route }) => (
    <Button 
      variant="ghost" 
      className={`w-full justify-between items-center px-4 py-3 mb-1 text-base transition-all duration-200 hover:bg-slate-700 group text-slate-300
        ${activeItem === route ? 'bg-slate-800 text-white' : 'hover:text-white'}`}
      onClick={() => {
        setActiveItem(route);
        navigate(`/${route}`);
      }}
    >
      <div className="flex items-center gap-3">
        <Icon className={`h-5 w-5 transition-transform duration-200 ${activeItem === route ? 'text-white' : ''}`} />
        <span className={`${activeItem === route ? 'font-medium' : ''}`}>{label}</span>
      </div>
      <ChevronRight className={`h-4 w-4 transition-all duration-200 
        ${activeItem === route ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-2'}`}
      />
    </Button>
  );

  return (
    <div className="flex flex-col justify-between h-screen w-64 bg-slate-900 border-r border-slate-700/40 shadow-lg">
      {/* Logo and Navigation */}
      <div className="space-y-8">
        {/* Logo */}
        <div className="px-6 py-5 border-b border-slate-700/40">
          <img 
            src={logo}
            alt="Logo" 
            className="h-8 opacity-90 hover:opacity-100 transition-opacity duration-200"
          />
        </div>

        {/* Navigation */}
        <div className="px-3 space-y-6">
          <div className="space-y-1">
            <h2 className="text-xs font-semibold text-slate-400 uppercase tracking-wider px-4 mb-2">
              Main Navigation
            </h2>
            <NavButton icon={Globe} label="Trips" route="trips" />
            <NavButton icon={Users} label="Customers" route="customers" />
          </div>
        </div>
      </div>

      {/* User Profile */}
      <div className="border-t border-slate-700/40">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button 
              variant="ghost" 
              className="w-full justify-start p-4 gap-3 hover:bg-slate-800 transition-colors duration-200 rounded-none text-slate-300"
            >
              <Avatar className="h-10 w-10 border-2 border-slate-700">
                <AvatarImage src="/api/placeholder/40/40" />
                <AvatarFallback className="bg-slate-800 text-white font-medium">
                  {userName?.charAt(0)?.toUpperCase()}
                </AvatarFallback>
              </Avatar>
              <div className="flex flex-col items-start text-sm">
                <span className="font-medium text-white">{userName}</span>
                <span className="text-slate-400 text-xs">{userEmail}</span>
              </div>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-56 bg-slate-900 text-slate-300 border-slate-700">
            <DropdownMenuItem className="gap-2 hover:bg-slate-800 hover:text-white">
              <Settings className="h-4 w-4" />
              <span>Settings</span>
            </DropdownMenuItem>
            <DropdownMenuSeparator className="bg-slate-700" />
            <DropdownMenuItem onClick={handleLogout} className="gap-2 text-red-400 hover:text-red-300 hover:bg-slate-800">
              <LogOut className="h-4 w-4" />
              <span>Logout</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  authorization: PropTypes.shape({
    emailAddress: PropTypes.string,
  })
};

Sidebar.defaultProps = {
  authorization: {
    emailAddress: '',
  }
};

export default Sidebar;
